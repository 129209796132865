.glossary-root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf9f8;
  height: 100%;
}

.glossary-root main {
  position: relative;
  height: 95%;
  max-width: 700px;
  width: 100%;
  background-color: white;
  border: 1px solid var(--solar-blue);
  border-radius: 10px;
  overflow: hidden;
}

.glossary-root header {
  background: var(--solar-blue);
  width: 100%;
  color: white;
  padding: 5px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.glossary-root header .back-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
}

.new-glossary-item {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}

.glossary-content {
  padding: 20px;
  overflow: auto;
  height: 100%;
}

.glossary-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px;
}

.glossary-item .word-container {
  display: flex;
}

.glossary-item .word {
  font-weight: bold;
  width: 100%;
  margin-bottom: 5px;
}

.glossary-item .description {
  color: black;
}

.glossary-item .delete-btn {
  cursor: pointer;
  color: grey;
}

/* .glossary-item input,
.glossary-item textarea {
  border: none;
  outline: none;
  resize: none;
  padding-left: 4px;
  border-radius: 0px;
  height: 100%;
}

.glossary-item textarea:first-child {
  flex: 1;
  padding-top: 20px;
}

.glossary-item textarea:nth-child(2) {
  flex: 2.5;
  margin: 5px;
  background-color: #f7f7f7;
  padding: 10px;
} */

.glossary-item .delete {
  cursor: pointer;
  margin-left: 5px;
}

/* .glossary-root footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--solar-blue);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glossary-button {
  width: 100px;
  background-color: white;
  color: var(--solar-blue);
  border-radius: 5px;
  padding: 5px;
} */


.glossary-form-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.2);
}

.glossary-item-form {
  padding: 20px;
  width: 400px;
  background-color: white;
}

.glossary-item-form textarea,
.glossary-item-form input {
  width: 100%;
}

.glossary-item-form footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}