.container {
  border: 0.5px solid black;
  margin: 0 auto;
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.sfs-logo-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
  width: 25%;
}

.container-heading {
  background-color: var(--solar-blue);
  color: #fff;
  padding: 1rem;
  text-align: center;
  height: 5rem;
  width: 100%;
  position: relative;
}

.container-heading .nav-btn {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.container-heading .nav-btn i[data-icon-name='ChevronDown'] {
  display: none;
}

.container-body-wrapper {
  width: 70%;
  height: 80%;
  margin-top: 4rem;
  position: relative;
}

.container-body {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
  background-color: var(--main-container-bg-color);
  border-radius: 1rem;
  overflow: hidden;
  padding-bottom: 2rem;
}

.container-header {
  position: relative;
  background-color: var(--solar-blue);
  width: 100%;
  color: #fff;
  padding: 1rem;
  text-align: center;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modules-wrapper {
  height: 100%;
  overflow: hidden auto;
  padding: 3rem 0;
}

.modules {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
}
.module {
  height: 275px;
  width: 300px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  margin: 0 1rem 1rem;
}

.module article:first-child {
  height: 80%;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module article:first-child:hover {
  border: 3px solid var(--solar-blue);
  border-width: 3px 3px 0;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.new {
  border: 3px dashed var(--solar-blue);
  border-width: 3px 3px 0;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.module article:nth-child(1) figure {
  display: inherit;
}

.module article:nth-child(1) img {
  width: 100%;
  margin: 0 auto;
  border-radius: 1rem;
}
.module article:nth-child(2) {
  padding: 1rem;
  text-align: center;
  background-color: var(--solar-blue);
  color: #fff;
}

.add-block-popup {
  width: 40%;
  position: fixed;
  border: 1px solid #cacaca;
  background-color: rgba(211, 207, 207, 1);
  display: flex;
  flex-direction: column;

  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms linear;
}

.add-block-popup form {
  height: 90vh;
  max-width: 500px;
  overflow: auto;
}

.block-elements {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;
}

.block-element {
  margin: 1rem 0;
}

.add-block-popup .popup-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--solar-blue);
  color: #fff;
  padding: 1rem;
  text-align: center;
  height: 2.5rem;
  width: 100%;
}
.close-icon {
  font-size: 2rem;
  cursor: pointer;
}

.content-root .block-elements input[type="text"],
.content-root input[type="number"] {
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  color: var(--solar-blue);
  font-weight: bold;
  border-radius: 0.4rem;
}

.content-root .block-elements input[type="number"] {
  width: 5rem;
  margin-left: 1rem;
}

.block-elements input[type="text"]:focus,
input[type="number"]:focus {
  border: 1px solid var(--solar-blue);
}

.block-elements input[type="file"] {
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--solar-blue);
  font-weight: bold;
  border-radius: 0.4rem;
  margin-left: 1rem;
}

.block-elements button {
  background: var(--solar-blue);
  color: #fff;
  border: 0;
  padding: 0.5rem 0.7rem;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
}

.back-button {
  position: absolute;
  top: 1%;
  left: -58px;
  font-size: 1.2rem;
  background: var(--solar-blue);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: margin 0.15s linear;
}

.back-button:hover,
.back-button:focus {
  transform: scale(1.04);
}

.template {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.template-images {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
}

.template-images img {
  width: 15%;
  margin: 0 1rem 1rem 0;
  border-radius: 1rem;
}

.template-images img:hover {
  border: 0.25rem solid var(--solar-blue);
}

.whats-next-container {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40%;
  width: 60%;
  background-color: white;
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  color: var(--solar-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.whats-next-container label:nth-child(1) {
  font-size: x-large;
  font-weight: bold;
}

.whats-next-container label:nth-child(2) {
  font-size: large;
}

.whats-next-container div {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.add-edit-template {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.add-edit-template button {
  margin: 1rem;
}

.block-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.color-picker-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-picker-group > input[type='color'] {
  height: 40px;
  width: 80px;
  margin-top: 5px;
}

.color-picker-row {
  display: flex;
  justify-content: space-evenly;
}

.image-thumbnail {
  min-height: 90px;
  min-width: 90px;
  max-height: 90px;
  max-width: 90px;
  position: relative;
  background-size: cover !important;
  margin-right: 10px;
  cursor: pointer;
}

.x-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  padding: 0px 4px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
}

.warning-msg {
  border-radius: 5px;
  font-size: 20px;
  padding: 8px;
  min-height: 100px;
  width: 90%;
  resize: vertical;
}

.drag-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: grab;
}

.glossary-btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.glossary-panel {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 20px;
}

.glossary-panel .word-item {
  margin-bottom: 6px;
  display: flex;
}
