.content-template {
  display: flex;
  height: 100%;
  justify-content: space-around;
}

.card-preview, .content-template > :first-child {
  width: 30%;
  min-width: 350px;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.card-preview {
  margin: 0px 20px;
  padding: 10px;
}

.content-template > :first-child > label {
  align-self: center;
}

.content-template > aside:last-child {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  height: 100%;
}

.content-template :nth-child(2) p {
  width: 100%;
  color: var(--solar-blue);
  font-size: large;
  font-weight: 500;
}

#sub-heading {
  padding: 0 2rem 0 2rem;
  border-radius: 1rem;
  background-color: #ececec;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

#fileUpload {
  display: flex;
  width: 100%;
  overflow: auto;
  height: fit-content;
}
