#main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#main-container nav {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 2rem;
}

.sfs-logo-header {
  width: 5rem;
}

.page-heading {
  flex-grow: 1;
  text-align: center;
  color: white;
  font-size: 3rem;
}

/* Login Container */

.login-container {
  flex-grow: 1;
  display: flex;
}

.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-group {
  margin-bottom: 1.5rem;
}


.mfa-container {
  max-width: 375px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
}

.mfa-container h2 {
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
}

.mfa-container p {
  font-size: 14px;
  margin: 8px 0;
  text-align: left;
}

.mfa-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mfa-container label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}