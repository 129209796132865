* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --solar-blue: #243f61;
  --main-container-bg-color: rgb(236, 236, 236, 0.781);
  --image-placeholder: url("https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/imagePlaceholder.png");
  --video-placeholder: url("https://sfs-mobile.s3.eu-central-1.amazonaws.com/assets/templates/videoPlaceholder.png");
}

.App {
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #faf9f9;
}

::-webkit-scrollbar-thumb {
  background-color: #243f61a9;
}

.clickable {
  cursor: pointer;
}
.banned {
  cursor: not-allowed;
}

.form-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.ms-TextField.error,
.ms-Dropdown-container.error {
  margin-bottom: 21px;
}

.ms-TextField.error *[role="alert"],
.ms-Dropdown-container.error *[role="alert"] {
  position: absolute;
}

.pac-container {
  z-index: 9999999;
  min-height: 100px;
  min-width: 200px;
}

.inlineflex .ms-ChoiceField {
  display: inline-block;
}

.inlineflex .ms-ChoiceFieldLabel {
  margin-right: 10px;
}
