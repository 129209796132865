.q-options-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.correct-answer {
  display: flex;
  align-content: space-between;
  justify-content: space-evenly;
  width: 100%;
  height: 35%;
}

.choose-correct-answer,
.answer-comment-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.choose-correct-answer {
  width: 40%;
}
.answer-comment-box {
  flex-grow: 1;
  justify-content: space-evenly;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

.answer-options {
  margin-top: 2rem;
}

.answer-options > span,
.answer-selected {
  padding: 0.5rem 1rem;
  margin: 0 1rem 0 0;
  background-color: white;
  border-radius: 0.6rem;
  font-weight: bolder;
  font-size: large;
  cursor: pointer;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

.answer-options span:hover {
  background-color: var(--solar-blue);
  color: white;
  cursor: pointer;
}
.answer-selected {
  background-color: var(--solar-blue) !important;
  color: white;
  cursor: pointer;
}

.draggable-answer-options {
  display: flex;
  flex-direction: column;
}

.draggable-answer-options > div,
.draggable-wrapper > div {
  background-color: var(--solar-blue);
  color: white;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
  width: fit-content;
  cursor: move;
}

span.grippy {
  content: "....";
  width: 10px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  line-height: 5px;
  padding: 3px 4px;
  cursor: move;
  vertical-align: middle;
  margin-top: -0.7em;
  margin-right: 0.3em;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 3px 0 0px white;
}
span.grippy::after {
  content: ".. .. .. ..";
}

.match-the-words {
  display: flex;
}
.choose-options {
  display: flex;
}
.choose-option {
  display: flex;
  margin: 3rem 0.3rem;
}

.correct-answers {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.question-options {
  display: flex;
  flex-direction: column;
}
.question-options div {
  margin: 2rem 2rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: 0.6rem;
  font-weight: bolder;
  font-size: large;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  width: fit-content;
}
.q3-answers {
  height: 75%;
  overflow-x: auto;
}
